.lobby-page {
    background-image: url('../../assets/lobby-background.jpeg');
    background-size: cover;
    background-position: center center;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}


.lobby-page__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Distributes space between items */
    background-image: url('../../assets/map.webp');
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    min-height: 500px;
    min-width: 490px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    font-family: 'BreitkopfFraktur';
}

.lobby-page__title {
    font-size: 48px;
    margin-top: 47px;
    margin-bottom: 0;

}

.lobby-page__sub-title {
    font-size: 36px;
    margin-top: 0px;

}

.lobby-page__start-game-button {
    align-self: center;
    /* Center the button */
    margin-top: auto;
    /* Pushes the button to the bottom */
}

.lobby-page__player-name {
    font-size: 24px;
}

.lobby-page__start-game-button {
    font-size: 20px;
}

.lobby-page__share-game-button {
    font-family: 'BreitkopfFraktur';
    margin-left: 10px;
    font-size: 20px;
}

.lobby-page__game-event-list {
    height: 100px;
    font-family: 'lato';
    margin-top: 40px;
    text-align: center;
}