.onboarding {
    background-image: url('../../assets/home-page-background.jpeg');
    background-size: cover;
    background-position: center center;
    width: 100vw;
    height: 100vh;
}

.onboarding__header {
    padding-top: 5vh;
    text-align: center;
    display: flex;
    justify-content: center;
}

.onboarding__logo{
    width: 100px;
    height: 100px;
    margin-right: 20px;
}

.onboarding__title {
    font-size: 7vh;
    font-family: 'BreitkopfFraktur';
}

.onboarding__scroll-container {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.onboarding__scroll-menu {
    width: 300px;
    min-height: 300px;
    background: #f3e5c0;
    border: 1px solid #a38757;
    border-radius: 8px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.4);
    padding: 20px;
    overflow: hidden;
    position: center;
    background-size: fit;
}

.onboarding__input{
    font-family: 'BreitkopfFraktur';
    font-size: 28px;
    padding: 5px;
    margin: 5px;
    text-align: center;
    width: 100%;
    border-radius: 0.5rem;
}

.onboarding__button {
    font-family: 'BreitkopfFraktur';
    font-size: 28px;
    padding: 5px;
    margin: 5px;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    border: 2px solid black;
    border-radius: 0.5rem;
}

.onboarding__button:last-child {
    margin-top: 40px;
}
