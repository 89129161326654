.container {
    background-color: #333;
    overflow-y: scroll;
    width: 600px;
    height: 300px;
    color: white;
}
.row {
    margin: 0;
    margin-left: 10px;
}