/* GameBoard.css */
:root {
    --game-card-width: 200px;
    --game-card-height: 15.6vw;
}

/* Flexbox container for harbor cards */
.harbor-cards-container,
.expedition-cards-container {
    display: flex;
    flex-wrap: wrap;
    /* Wrap cards to the next row when there's not enough space */
    justify-content: flex-start;
    /* Start from the left, you can change this to 'center' or 'space-between' as needed */
    gap: 16px;
    /* Adjust the gap between cards as needed */
    min-height: calc(var(--game-card-height) * 0.5 + 3vw);
    padding: 16px;
    /* Add some padding for spacing between the frame and card content */
    margin: 8px;
    /* Adjust the margin as needed */
    /*width: 100%;*/
}

.expedition-cards-container .resizable-card {
    width: calc(var(--game-card-width) * 0.8);
    /* Adjust the percentage as needed */
}

@keyframes fadeInScaleUp {
    from {
        opacity: 0;
        transform: scale(0.8);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}


.resizable-card {
    width: var(--game-card-width);
    /* Adjust the percentage as needed */
    max-width: 100%;
    /* Ensure the image doesn't exceed its original size */
    height: auto;
    /* Maintain the image's aspect ratio */
    position: relative;
    animation: fadeInScaleUp 0.5s ease-out;
}

@keyframes fadeOut {
    to {
        opacity: 0;
        visibility: hidden;
    }
}

.card-fading-out {
    animation: fadeOut 0.5s ease-out forwards;
}

.card-container.highlighted {
    --bs-card-border-color: chartreuse;
    --bs-card-border-width: thick;
    border: chartreuse;
    border-width: thick;
}

.card-container {
    display: flex;
    flex-direction: column;
}

.game-board {
    box-sizing: border-box;
    width: 100%;
}

.wooden-frame {
    border: 4px solid #8B4513;
    /* Set the border style and color to create a wooden frame effect */
    /*display: inline-block; !* Ensure that the frame wraps around the card content *!*/
    border-radius: 8px;
    /* Add rounded corners for a smoother appearance */
    width: 100%;
    background-color: rgba(155, 91, 49, 0.40);
    /* Adjust the alpha (0.5) for the desired opacity */
    box-sizing: border-box;
}