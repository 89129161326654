.player-info-cards-container .card-container .resizable-card {
    width: calc(var(--game-card-width) / 1.5);
}

.player-info-cards-container {
    position: relative;
    display: flex;
    flex-wrap: wrap; /* Wrap cards to the next row when there's not enough space */
    justify-content: flex-start; /* Start from the left, you can change this to 'center' or 'space-between' as needed */;
    z-index: 1;
}