.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    /*top: 100%;*/
    /*left: 0%;*/
    /*transform: translate(-50%, -50%);*/
    /*-ms-transform: translate(-50%, -50%);*/
    text-align: left;
}

.player-info-container:hover .player-frame {
    opacity: 0.3;
}

.player-info-container:hover .middle {
    opacity: 1;
}

.player-info-container {
    position: relative;
}

.player-frame.main-player {
    border: 4px solid #20c997;
    border-radius: 8px;
}
.player-frame.secondary-player {
    border: 4px solid #208ec9;
    border-radius: 8px;
}

.symbol-img {
    max-width: calc(var(--game-card-width) * 0.25);
    width: 100%;
    height: auto;
}

.player-info-card {
    background-color: rgba(255, 255, 255, 0.4);
    width: inherit;
    max-width: 100%; /* Restrict the maximum width to the parent container */
}

table {
    border: 1px solid;
    width: 100%;
    table-layout: fixed;
    text-align: center;
}

.tr {
    border: 1px solid;
    text-align: center;
}

.th {
    border: 1px solid;
    width: auto; /* Allow table headers to adjust their width */
    white-space: nowrap;
    text-align: center;
}

.td {
    border: 1px solid;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.player-info__trader{
    font-style: normal; 
    font-weight: 900;
    text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 
                 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black,
                 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
}