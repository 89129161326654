.game-page {
    background-image: url('../../assets/map-background3-vintage.jpeg'); /* Replace with actual path */
    background-size: cover;
    background-position: center center;
    height: 100%;
    min-height: 100vh;
}

.game-page-div {
    display: flex;
}

.game-board-div {
    flex: 1;
}

.players-list-div {
    flex: 1;
}

.modal {
    display: block;
    --bs-modal-bg: black;
    opacity: 0.7;
}


.winner-modal-header {
    justify-content: center;
    color: white;
    outline-color: black;
    outline-width: 5px;
}

.winner-modal-text {
    text-align: center;
    color: white;
    text-shadow: black 0 0 1px,   black 0 0 1px,   black 0 0 1px,
    black 0 0 1px,   black 0 0 1px,   black 0 0 1px,
    black 0 0 1px,   black 0 0 1px,   black 0 0 1px,
    black 0 0 1px,   black 0 0 1px,   black 0 0 1px;
}

.players-list-div {
    width: 20%;
    max-width: 20%;
    box-sizing: border-box;
    /*max-width: 25vw;*/
}
.game-board-div {
    width: 80%;
    box-sizing: border-box;
}